
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
  },
}
