
import EditorTipTap from 'Editors/EditorTipTap'
import ControllerFooter from 'Controllers/ControllerFooter'
export default {
  components: {
    ControllerFooter,
    EditorTipTap,
  },
  data() {
    return {
      inView: false,
      logoOptions: [
        {
          name: 'logo',
          label: 'Logo',
          type: 'image',
          size: '100x400',
        },
      ],
    }
  },
  mounted() {
    this.$fb.init()
  },
  methods: {
    callback() {
      console.log('poslan')
    },
  },
}
