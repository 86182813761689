// Types
import mixins from"vue-typed-mixins";// Utils
import"lazysizes";import"lazysizes/plugins/parent-fit/ls.parent-fit";// import Plyr from 'plyr'
import"./CmsImage.css";// Mixins
import{WithRefs}from"../../utils/withRefs";import{WithPartialInject}from"../../utils/withInject";import{MediaFormat}from"../../enums/Media";const dummyInject=WithPartialInject("dummyMode"),isInstagramMedia=a=>a&&"media_type"in a&&"caption"in a,CmsImage=mixins(WithRefs(),dummyInject).extend({props:{media:{type:[Array,Object],default:null},customSize:{type:String,default:null},link:{type:String,default:null},placeholder:{type:String,default:""},recommendedSize:{type:String,default:null},disableLazyLoad:{type:Boolean,default:!1},controls:{type:Boolean,default:!1},width:{type:[String,Number],default:void 0},height:{type:[String,Number],default:void 0},playsinline:{type:Boolean,default:!1},preload:{type:String,default:""}},data(){return{player:null// player: null as Plyr | null,
}},computed:{returnLink(){return this.dummyMode?this.returnPlaceholderImage:this.link?this.link:isInstagramMedia(this.media)?this.media.media_url:this.$cmsMedia({media:this.media,customSize:this.customSize})},returnAlt(){return this.link?"image":this.$cmsMedia({media:this.media,alt:!0})},returnPlaceholderImage(){return this.placeholder||this.$siteLogo||"/cms-public/placeholders/broken-link.svg"},isDocument(){const{media:a}=this;return!!a&&"mime"in a&&["application/pdf","application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/vnd.ms-powerpoint","application/vnd.openxmlformats-officedocument.presentationml.presentation","text/plain","text/csv","application/rtf","application/x-cfb","application/zip","application/vnd.oasis.opendocument.text","application/vnd.oasis.opendocument.spreadsheet","application/vnd.oasis.opendocument.presentation"].includes(a.mime);// return 'mime' in media && media.mime!.includes('pdf')
},isVideo(){const{media:a}=this;return!!a&&(isInstagramMedia(a)?a.media_type.includes("VIDEO"):"mime"in a&&a.mime.includes("video"))}},mounted(){//   if (!this.isVideo || !this.$refs?.video) return
//   this.player = new Plyr(this.$refs.video)
},watch:{media:{handler(a){if(this.isVideo&&this.$refs.video&&this.$isInEditMode)// TODO: current version 0.107.0, this was some kind of legacy code we had to adjust few days ago according to new image format handling. Seems like it is never being triggered but lets keep it just in case. Test this and remove if possible.
// this.player = null
if(!this.disableLazyLoad&&this.$refs.lazyLoad){const b=this.$cmsMedia({media:a,customSize:this.customSize}),c=this.$refs.imageSource,d=this.$refs.lazyLoad;d&&(Array.isArray(b)&&b.length&&Array.isArray(c)&&c.length?b.forEach((a,b,e)=>b<e.length-1?c[b].srcset=a:d.src=a):"string"==typeof b&&(d.src=b))}else if(this.player){this.$cmsMedia({media:this.media.poster,customSize:this.customSize});//   this.player.source = {
//     ...this.player.source,
//     poster:
//       typeof newPoster === 'string'
//         ? newPoster
//         : this.returnPlaceholderImage,
//   }
}// this.player = new Plyr(this.$refs.video)
}},"$route.query.editPage":{handler(a){!a||this.media&&(!Array.isArray(this.media)||this.media.length)||this.link||this.returnPlaceholder()}}},methods:{emitClick(){this.$emit("click")},createImageSource(a){const b=a.split(".").pop(),c=[MediaFormat.Avif,MediaFormat.Webp,MediaFormat.Jpeg,MediaFormat.Png];let d=null;return b&&c.includes(b)&&(d=`image/${b}`),this.$createElement("source",{ref:"imageSource",attrs:{srcset:a,type:d},refInFor:!0})},createVideoSource(a){return this.$createElement("source",{ref:"videoSource",attrs:{src:a,type:this.media.mime}})},createDocument(){return this.$createElement("a",{ref:"fileLink",attrs:{target:"_blank",href:this.media.newPath,type:this.media.mime}},[this.media.title])},createImage(a=""){// forcedSrc means that image is inside picture tag since there is a media source for each media format
return this.$createElement("img",{...(!this.disableLazyLoad&&{ref:"lazyLoad"}),attrs:{...(!this.disableLazyLoad&&{loading:"lazy"}),width:this.width,height:this.height,alt:this.returnAlt,src:a||this.returnLink},class:this.disableLazyLoad?"staticloaded":"lazyLoad",on:{load:this.onSuccess,// error: this.onError,
click:this.emitClick}})},createPicture(){return Array.isArray(this.returnLink)?this.$createElement("picture",{},[this.returnLink.map((a,b,c)=>b<c.length-1?this.createImageSource(a):this.createImage(a))]):this.createImage()},createVideo(){return this.$createElement("div",{class:"video-wrapper h-full"},[this.$createElement("video",{ref:"video",domProps:{poster:this.$cmsMedia({media:this.media.poster,customSize:this.customSize}),"data-poster":this.$cmsMedia({media:this.media.poster,customSize:this.customSize})},attrs:{controls:this.controls,width:this.width,height:this.height,playsinline:this.playsinline,preload:this.preload||void 0},class:"h-full"},// Video element will always still use old gauss-gcr redirects, there are no alternative paths for videos
[this.createVideoSource(this.returnLink)])])},createContent(){return this.isVideo?this.createVideo():this.isDocument?this.createDocument():Array.isArray(this.returnLink)&&this.returnLink.length?this.createPicture():this.createImage()},onError(a){this.returnPlaceholder(a)},onSuccess(a){var b;let c=this.$refs.lazyLoad;if(a&&(c=a.target),!!c){const a=(null===(b=c)||void 0===b?void 0:b.src)||"";if(-1===a.search(this.returnPlaceholderImage)){var d;if(null!==(d=c)&&void 0!==d&&d.style.removeProperty){var e;null===(e=c)||void 0===e?void 0:e.style.removeProperty("object-fit")}else{var f;null===(f=c)||void 0===f?void 0:f.removeAttribute("object-fit")}}}},returnPlaceholder(a){let b=this.returnPlaceholderImage;this.$isInEditMode&&this.recommendedSize&&(b=`https://via.placeholder.com/${this.recommendedSize}`),this.setPlaceholdersToImageSources(b);let c=this.$refs.lazyLoad;a&&(c=a.target),c&&(c.src=b,c.setAttribute("style","object-fit: none"))},setPlaceholdersToImageSources(a){var b;const c=null===(b=this.$refs)||void 0===b?void 0:b.imageSource;Array.isArray(c)&&c.length&&c.forEach(b=>b.srcset=a)}},render(){return this.createContent()}});export default CmsImage;