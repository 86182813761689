import { render, staticRenderFns } from "./ProductList.vue?vue&type=template&id=78ead034&scoped=true"
import script from "./ProductList.vue?vue&type=script&lang=js"
export * from "./ProductList.vue?vue&type=script&lang=js"
import style0 from "./ProductList.vue?vue&type=style&index=0&id=78ead034&prod&lang=scss&scoped=true"
import style1 from "./ProductList.vue?vue&type=style&index=1&id=78ead034&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78ead034",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerLayeredNavigation: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerLayeredNavigation.js').default,Search: require('/home/node/app/assets/icons/search.vue').default,ControllerSearch: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerSearch.js').default,UiProduct: require('/home/node/app/components/ui/uiProduct.vue').default,ControllerProduct: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerProduct.js').default,ControllerProductList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductList.js').default,ControllerCategory: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerCategory.js').default})
