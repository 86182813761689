import Vue from 'vue'
import moment from 'moment'
import numeral from 'numeral'

Vue.mixin({
  methods: {
    $formatMonth(date) {
      return moment(date).format('D.M.')
    },
    $momentFormat(date, format) {
      return moment(date).format(format)
    },
    $formatHour(date) {
      return moment(date).format('HH')
    },
    $formatDate(post) {
      if (post.published_at)
        return moment(post.published_at).format('DD MMM YYYY')

      return moment(post.created_at).format('DD MMM YYYY')
    },

    // Check NEW tag
    $checkNewTag(product) {
      if (product.tags) {
        for (let i = 0; i < product.tags.length; i++) {
          if (product.tags[i].name === 'NOVO') {
            return true
          }
        }
      }
    },

    $returnDiscountPercent(product) {
      /* eslint-disable */
      if (product.hasOwnProperty('discount')) {
        const oldPrice = product.retailPrice
        const newPrice = product.discount.retailPrice
        const calculatePrice = (oldPrice - newPrice) / oldPrice
        const calculatePercent = calculatePrice * 100
        return calculatePercent.toFixed()
      } else return false
      /* eslint-enable */
    },
    $checkTag(product, tag) {
      if (product.tags) {
        for (let i = 0; i < product.tags.length; i++) {
          if (product.tags[i].name === tag) {
            return true
          }
        }
      }
    },
    // Check NEW tag
    $checkTopTag(product) {
      if (product.tags) {
        for (let i = 0; i < product.tags.length; i++) {
          if (product.tags[i].name === 'NAJPRODAVANIJE') {
            return true
          }
        }
      }
    },

    $shareUrl() {
      if (process.browser) {
        return window.location.href
      }
    },

    // Price in euro
    $returnEuroPrice(price, locale) {
      if (locale === 'hr') {
        const conversion = 7.5345
        const euroPrice = price / conversion
        let formatedPrice = null

        formatedPrice = this.$numeralFormat(euroPrice, 'EUR')

        return formatedPrice
      }
    },
    $numeralFormat(number, currencyFormat = this.$store.state.currencyFormat) {
      const format = numeral(number).format('0,0.00 $')
      const switchDecimalSeparator = format
        .replace(/,/g, '_')
        .replace(/\./g, ',')
        .replace(/_/g, '.')
      return switchDecimalSeparator.replace('$', currencyFormat)
    },
  },
})
