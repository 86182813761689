import { render, staticRenderFns } from "./SearchView.vue?vue&type=template&id=da9a86d0&scoped=true"
import script from "./SearchView.vue?vue&type=script&lang=js"
export * from "./SearchView.vue?vue&type=script&lang=js"
import style0 from "./SearchView.vue?vue&type=style&index=0&id=da9a86d0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da9a86d0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiInput: require('/home/node/app/components/ui/uiInput.vue').default,Search: require('/home/node/app/assets/icons/search.vue').default,ControllerSearch: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerSearch.js').default})
