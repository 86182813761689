
import ControllerPostList from 'Controllers/ControllerPostList'
export default {
  components: {
    ControllerPostList,
  },
  data() {
    return {
      filterVisible: false,
      options: {
        page: 1,
      },
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: ['image-bg'],
      },
    }
  },
  mounted() {
    this.filterVisible = false
  },
  methods: {
    filterToggle() {
      this.filterVisible = !this.filterVisible
    },
  },
}
