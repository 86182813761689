
export default {
  data() {
    return {
      showPassword: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      const body = document.documentElement
      body.classList.add('register-page')
    })
  },
  destroyed() {
    const body = document.documentElement
    body.classList.remove('register-page')
  },
  methods: {
    goToHome() {
      this.$router.push('/')
    },
  },
}
