import { render, staticRenderFns } from "./PostList.vue?vue&type=template&id=79f2e81a&scoped=true"
import script from "./PostList.vue?vue&type=script&lang=js"
export * from "./PostList.vue?vue&type=script&lang=js"
import style0 from "./PostList.vue?vue&type=style&index=0&id=79f2e81a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79f2e81a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Search: require('/home/node/app/assets/icons/search.vue').default,Btn: require('/home/node/app/components/ui/btn.vue').default,ControllerSearch: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerSearch.js').default,BlogFilter: require('/home/node/app/assets/icons/blogFilter.vue').default,ControllerPostCategoryList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategoryList.js').default,UiPost: require('/home/node/app/components/ui/uiPost.vue').default,ControllerPostList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js').default})
