
import veeno from 'veeno'
import 'nouislider/distribute/nouislider.min.css'

export default {
  components: {
    veeno,
  },
  inject: ['category'],
  data() {
    return {
      layeredRef: 'layeredNav',
      filtersVisible: true,
      mobileFiltersVisible: false,
      min: null,
      max: null,
      options: {
        page: 1,
        itemsPerPage: 3,
      },
      details: null,
    }
  },
  mounted() {
    this.$refs.category.options.itemsPerPage = 6
  },
  methods: {
    detailsBoolean(e) {
      // if (
      //   e.target.parentElement.open &&
      //   e.srcElement.children &&
      //   e.srcElement.children.length
      // ) {
      //   e.srcElement.children[1].textContent = '-'
      // } else {
      //   e.srcElement.children[1].textContent = '+'
      // }
    },
    clearFilters() {
      if (this.$refs[this.layeredRef]) {
        this.$refs[this.layeredRef].resetFilters()
      }
    },
    sliderUpdate(filters, val) {
      filters.minValue = parseInt(val.values[0])
      filters.maxValue = parseInt(val.values[1])
    },

    toggleFilters() {
      this.filtersVisible = !this.filtersVisible
    },
    toggleMobileFilters() {
      this.mobileFiltersVisible = !this.mobileFiltersVisible
    },
    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 500)
    },
  },
}
