

const asyncCartView = () => import('../../../components/cmsOverrides/CartView')
const asyncCategoryList = () => import('../../../components/cmsOverrides/CategoryList')
const asyncCheckoutSuccessView = () => import('../../../components/cmsOverrides/CheckoutSuccessView')
const asyncCheckoutView = () => import('../../../components/cmsOverrides/CheckoutView')
const asyncErrorView = () => import('../../../components/cmsOverrides/ErrorView')
const asyncFooterElement = () => import('../../../components/cmsOverrides/FooterElement')
const asyncForgotPasswordView = () => import('../../../components/cmsOverrides/ForgotPasswordView')
const asyncHeaderElement = () => import('../../../components/cmsOverrides/HeaderElement')
const asyncLoginView = () => import('../../../components/cmsOverrides/LoginView')
const asyncPostCategory = () => import('../../../components/cmsOverrides/PostCategory')
const asyncPostList = () => import('../../../components/cmsOverrides/PostList')
const asyncPostView = () => import('../../../components/cmsOverrides/PostView')
const asyncProductList = () => import('../../../components/cmsOverrides/ProductList')
const asyncProductListAll = () => import('../../../components/cmsOverrides/ProductListAll')
const asyncProfileView = () => import('../../../components/cmsOverrides/ProfileView')
const asyncRegisterView = () => import('../../../components/cmsOverrides/RegisterView')
const asyncSearchView = () => import('../../../components/cmsOverrides/SearchView')
import CartView from '../../../components/cmsOverrides/CartView'
import CategoryList from '../../../components/cmsOverrides/CategoryList'
import CheckoutSuccessView from '../../../components/cmsOverrides/CheckoutSuccessView'
import CheckoutView from '../../../components/cmsOverrides/CheckoutView'
import ErrorView from '../../../components/cmsOverrides/ErrorView'
import FooterElement from '../../../components/cmsOverrides/FooterElement'
import ForgotPasswordView from '../../../components/cmsOverrides/ForgotPasswordView'
import HeaderElement from '../../../components/cmsOverrides/HeaderElement'
import LoginView from '../../../components/cmsOverrides/LoginView'
import PostCategory from '../../../components/cmsOverrides/PostCategory'
import PostList from '../../../components/cmsOverrides/PostList'
import PostView from '../../../components/cmsOverrides/PostView'
import ProductList from '../../../components/cmsOverrides/ProductList'
import ProductListAll from '../../../components/cmsOverrides/ProductListAll'
import ProfileView from '../../../components/cmsOverrides/ProfileView'
import RegisterView from '../../../components/cmsOverrides/RegisterView'
import SearchView from '../../../components/cmsOverrides/SearchView'

const asyncCookieElement = () => import('./elements/cookie/CookieElement')
const asyncMaintenanceView = () => import('./shop/MaintenanceView')
const asyncCartModalView = () => import('./shop/CartModalView')
const asyncSocialLoginView = () => import('./shop/SocialLoginView')
const asyncConfirmEmailView = () => import('./shop/ConfirmEmailView')
const asyncBundleCustomizationModalView = () => import('./shop/BundleCustomizationModalView')
import CookieElement from './elements/cookie/CookieElement'
import MaintenanceView from './shop/MaintenanceView'
import CartModalView from './shop/CartModalView'
import SocialLoginView from './shop/SocialLoginView'
import ConfirmEmailView from './shop/ConfirmEmailView'
import BundleCustomizationModalView from './shop/BundleCustomizationModalView'

export {asyncCartView, CartView, asyncCategoryList, CategoryList, asyncCheckoutSuccessView, CheckoutSuccessView, asyncCheckoutView, CheckoutView, asyncErrorView, ErrorView, asyncFooterElement, FooterElement, asyncForgotPasswordView, ForgotPasswordView, asyncHeaderElement, HeaderElement, asyncLoginView, LoginView, asyncPostCategory, PostCategory, asyncPostList, PostList, asyncPostView, PostView, asyncProductList, ProductList, asyncProductListAll, ProductListAll, asyncProfileView, ProfileView, asyncRegisterView, RegisterView, asyncSearchView, SearchView, asyncCookieElement, CookieElement, asyncMaintenanceView, MaintenanceView, asyncCartModalView, CartModalView, asyncSocialLoginView, SocialLoginView, asyncConfirmEmailView, ConfirmEmailView, asyncBundleCustomizationModalView, BundleCustomizationModalView}
