
export default {
  data() {
    return {
      editMode: false,
    }
  },
  methods: {
    openEditMode() {
      this.editMode = true
    },
    closeEditMode() {
      this.editMode = false
    },
    save() {
      this.closeEditMode()
      if (this.$i18n.locale === 'hr') {
        this.$toast.success('Uspješno spremljeno!')
      } else this.$toast.success('Changes successfully saved!')
    },
  },
}
