
import ControllerShipping from 'Controllers/checkout/ControllerShipping'
import ControllerAddress from 'Controllers/checkout/ControllerAddress'
import ControllerCart from 'Controllers/ControllerCart'

import ControllerPayment from 'Controllers/checkout/ControllerPayment'
export default {
  components: {
    ControllerPayment,
    ControllerShipping,
    ControllerAddress,
    ControllerCart,
  },
  data() {
    return {
      showCart: false,
      articlesOpen: false,
      activeDeliveryMethod: null,
    }
  },
  methods: {
    toggleArticles() {
      this.articlesOpen = !this.articlesOpen
    },
    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 500)
    },
  },
}
