
import ControllerError from 'Controllers/ControllerError'
import ControllerSearch from 'Controllers/ControllerSearch'
export default {
  components: {
    ControllerError,
    ControllerSearch,
  },
  head() {
    return {
      bodyAttrs: {
        class: ['error-bg'],
      },
    }
  },
}
