import { render, staticRenderFns } from "./CartView.vue?vue&type=template&id=fb5fb2c0"
import script from "./CartView.vue?vue&type=script&lang=js"
export * from "./CartView.vue?vue&type=script&lang=js"
import style0 from "./CartView.vue?vue&type=style&index=0&id=fb5fb2c0&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Remove: require('/home/node/app/assets/icons/remove.vue').default,Btn: require('/home/node/app/components/ui/btn.vue').default,ArrowCircle: require('/home/node/app/assets/icons/arrow-circle.vue').default,ControllerCart: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerCart.js').default})
