import { render, staticRenderFns } from "./CheckoutView.vue?vue&type=template&id=55d8c67c"
import script from "./CheckoutView.vue?vue&type=script&lang=js"
export * from "./CheckoutView.vue?vue&type=script&lang=js"
import style0 from "./CheckoutView.vue?vue&type=style&index=0&id=55d8c67c&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiInput: require('/home/node/app/components/ui/uiInput.vue').default,UiSelect: require('/home/node/app/components/ui/uiSelect.vue').default,Btn: require('/home/node/app/components/ui/btn.vue').default,ControllerAddress: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerAddress.js').default,ControllerShipping: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerShipping.js').default,ControllerPayment: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerPayment.js').default,Check: require('/home/node/app/assets/icons/check.vue').default,ArrowCircle: require('/home/node/app/assets/icons/arrow-circle.vue').default,ControllerCart: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerCart.js').default})
