import { render, staticRenderFns } from "./LoginView.vue?vue&type=template&id=5a9edc65"
import script from "./LoginView.vue?vue&type=script&lang=js"
export * from "./LoginView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiInput: require('/home/node/app/components/ui/uiInput.vue').default,OpenedEye: require('/home/node/app/assets/icons/opened-eye.vue').default,ClosedEye: require('/home/node/app/assets/icons/closed-eye.vue').default,Btn: require('/home/node/app/components/ui/btn.vue').default,ControllerSocialLoginButtons: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLoginButtons.js').default,ControllerLogin: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerLogin.js').default})
