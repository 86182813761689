// Types
import mixins from"vue-typed-mixins";// Utils
import camelCase from"lodash/camelCase";import merge from"lodash/merge";import isObject from"lodash/isObject";import consola from"consola";// Mixins
import{InjectFrom,InjectFromTwo}from"../utils/withInject";const dummyInject=InjectFrom().keys("dummyMode"),layoutElementInject=InjectFrom().keys("sectionName"),editorContainerInject=InjectFrom().keys("registerEditor","unregisterEditor","registeredEditors"),sectionInject=InjectFromTwo().keys("setComponentMeta"),componentDataInject=InjectFrom().keys("componentData");function ensureValue(a,b){// if value is not defined, then we return default value anyway, doesn't matter what it is
return void 0===a||null===a?b:void 0===b||null===b?a:isObject(a)&&isObject(b)?merge(b,a):a;// if there is no default value, then there is nothing to check by
// For now we will keep it simple and only handle objects to make sure no keys
// from default value are missing - that could happen if we add new keys
// and load value from existing section that still doesn't have them
}export function isElementComponent(a){return"static-element"===a.type}const EditorBase=a=>mixins(layoutElementInject,sectionInject,componentDataInject,dummyInject,editorContainerInject).extend({props:{name:{type:String},value:{type:[String,Number,Object]},iteratorClass:{type:String,default:""},clientFetch:{type:Boolean,default:!1}},fetchOnServer:function(){var a,b;return!this.$config.globalEditorFetchOnClient&&!(null!==(a=this.$options)&&void 0!==a&&null!==(b=a.propsData)&&void 0!==b&&b.clientFetch);// @ts-ignore
},computed:{duplicateEditorNames(){return!!this.registeredEditors&&1<this.registeredEditors.filter(a=>a.name===this.computedName).length},sectionMeta(){const{componentData:a}=this;return a?isElementComponent(a)?a.meta:a.linkMeta:void 0},computedValue:{get(){const{computedName:b,sectionMeta:c}=this,d=a();// TODO: this is hacked to hell with all the type casting
return b?c?ensureValue(c[b],d):d:ensureValue(this.value,d)},set(a){const{componentData:b,computedName:c}=this;if(!c)return void this.$emit("input",a);if(!b)return void consola.error("Editor "+this.name+" is not in section.");if(isElementComponent(b)){var d;return null===(d=this.setComponentMeta)||void 0===d?void 0:d.call(this,b,c,a)}var e;return null===(e=this.setComponentMeta)||void 0===e?void 0:e.call(this,b,c,a)}},computedName(){return camelCase(this.name)}},mounted(){var a;this.computedName&&(null===(a=this.registerEditor)||void 0===a?void 0:a.call(this,{name:this.computedName,instance:this}))},destroyed(){var a;this.computedName&&(null===(a=this.unregisterEditor)||void 0===a?void 0:a.call(this,{name:this.computedName,instance:this}))}});export default EditorBase;