
// import ControllerFooter from 'Controllers/ControllerFooter'
// import ControllerHeader from 'Controllers/ControllerHeader'
// import ControllerNavigationBar from 'Controllers/ControllerNavigationBar'
export default {
  // components: {
  //   ControllerFooter,
  //   ControllerHeader,
  //   ControllerNavigationBar,
  // },
  data() {
    return {
      visibleSubmenu: null,
      visibleChildSubmenu: null,
      mobileMenu: false,
      languageDropDown: false,
      mobileView: false,
      cartItems: 0,
    }
  },
  mounted() {
    this.cartItems = this.$refs.cart.cartItems.length
  },

  watch: {
    $route: {
      handler() {
        this.mobileMenu = false
        this.languageDropDown = false
        this.visibleSubmenu = null

        if (process.browser) {
          document.body.classList.remove('modal-html-open')
          document.body.classList.remove('modal-html-open')
        }
      },
    },
    mobileMenu: {
      handler(val) {
        if (val) {
          document.body.classList.add('modal-html-open')
        } else {
          document.body.classList.remove('modal-html-open')
        }
      },
    },
  },
  computed: {
    total() {
      let sum = 0
      if (this.$refs.cart) {
        this.$refs.cart.cartItems.forEach(
          (product) => (sum = sum + product.qty)
        )
      }
      return sum
    },
  },

  methods: {
    resizeEvent() {
      this.menuPadding()
    },
    socialColors(network) {
      if (network === 'Facebook') {
        return 'bg-iskra-orange'
      } else if (network === 'Instagram') {
        return 'bg-iskra-red'
      } else if (network === 'Instagram') {
        return 'bg-iskra-red'
      } else {
        return 'bg-primary'
      }
    },
    openSubMenu(index) {
      if (this.visibleSubmenu) {
        this.visibleSubmenu = null
      } else {
        this.visibleSubmenu = index
      }
    },
    openChildSubMenu(index) {
      if (this.visibleChildSubmenu) {
        this.visibleChildSubmenu = null
      } else {
        this.visibleChildSubmenu = index
      }
    },
    languageDropDownToggle() {
      this.languageDropDown = !this.languageDropDown
    },
    openMobileMenu() {
      this.mobileMenu = !this.mobileMenu
    },
  },
}
