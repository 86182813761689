import mixins from"vue-typed-mixins";// Mixins
import ControllerBase from"./ControllerBase";/**
 * @param formType - for which form do we have to fetch consents (Custom forms will always send their slug, instead of using Enum FormWithConsent)
 * This param is optional because of ControllerCustomForm. For all the other forms (Register, Contact, etc), we can use FormWithConsent enum to fetch assigned consents for them.
 * But in case of custom forms, each custom form has its own form-slug passed as a prop to ControllerCustomForm.
 * And we will use it to lazy fetch assigned consents from ControllerCustomForm.
 * */const Consentable=a=>mixins(ControllerBase).extend({data(){return{formType:a||"",assignedConsents:[],assignedConsentsUniqueNumbers:[],requiredConsentsUniqueNumbers:[],consentsFetched:!1,form:{acceptedConsents:[]}}},// TODO: Move to created? Fetch will be overriden with components fetch, while created wont
async fetch(){this.formType&&(await this.initConsents())},methods:{//New method introduced because of ControllerCustomForm,
//this method should be called inside Controllers which cannot immediately set formType mixin argument
async lazyInitConsents(a){this.formType=a,await this.initConsents()},async initConsents(){const a=await this.fetchConsentAssignation();if(a){const a=await this.fetchAssignedConsentsDetails();a&&(this.appendSymbolsToRequiredConsents(),this.consentsFetched=!0)}},/**
       * Fetch ids of consents assigned available for this form + Fetch ids of REQUIRED consents for this form to be submitted
       * After successful fetch, set assigned and required consents
       * @returns {Promise} fetch success
       */async fetchConsentAssignation(){const a=await this.$api.cms.consent.filter({perPage:50});if(a.errored)return!1;const b=a.data.data;return this.setAssignedConsentUniqueNumbers(b),this.setRequiredConsentUniqueNumbers(b),!0},setAssignedConsentUniqueNumbers(a){var b;const d=(null===(b=a.find(a=>a.assigned_to===this.formType))||void 0===b?void 0:b.consents)||[],e=this.assignedConsentsUniqueNumbers;e.splice(0,e.length,...d)},setRequiredConsentUniqueNumbers(a){var b;const d=(null===(b=a.find(a=>a.assigned_to===this.formType))||void 0===b?void 0:b.requiredConsents)||[],e=this.requiredConsentsUniqueNumbers;e.splice(0,e.length,...d)},/**
       * Fetch consent details and set details for every assigned consent
       * @returns {Promise} fetch success
       */async fetchAssignedConsentsDetails(){const a=await this.$api.gdpr.web.consent.filter({forWeb:!0});if(a.errored)return!1;const b=this.assignedConsents;return b.splice(0,b.length,...a.data.records.filter(a=>this.assignedConsentsUniqueNumbers.includes(a.uniqueNumber))),!0},/**
       * Appends star symbol to required consents innerHtml
       * */appendSymbolsToRequiredConsents(){this.assignedConsents.forEach(a=>{this.requiredConsentsUniqueNumbers.includes(a.uniqueNumber)&&(a.displayName+="<span class=\"required-star-symbol\">*</span>")})},/**
       * Check consent requirements
       */async validateConsents(a=""){// Fetch errored
let b="";if(!this.consentsFetched)return b="error.consentsNotFetched",this.$toast.error(this.$t(b)),!1;// None needed
if(!this.assignedConsents.length||!this.requiredConsentsUniqueNumbers.length)return!0;const c=this.requiredConsentsUniqueNumbers.every(a=>this.form.acceptedConsents.includes(a));// Consent is needed, but was not given
if(this.assignedConsents.length&&!c)return b="core.notifications.consentRequired",this.$toast.error(this.$t(b)),!1;if(a){const c=await this.submitConsentLog(a);if(c.errored)return b="error.failedConsentLog",this.$toast.error(this.$t(b)),!1}return!0},// /**
//  * Fetch ids of consents required for this form to be submitted
//  * @returns {Promise} array of required consent ids or
//  * undefined if fetch errored
//  */
// async fetchRequiredConsentIds() {
//   const consentAssignations = await this.$api.cms.consent.filter()
//   if (consentAssignations.errored) return
//   const needed = consentAssignations.data.data.find(
//     (assignation) => assignation.assigned_to === this.formType
//   )
//   if (!needed?.consents.length) return []
//   const consents = await this.$api.gdpr.web.consent.filter()
//   if (consents.errored) return
//   return consents.data.records
//     .filter((r) => needed.consents.includes(r.uniqueNumber))
//     .map((r) => r.id)
// },
/**
       * Submit consent log for given consent ids
       * @param {number[]} requiredConsentIds array of ids
       * @returns {Promise} standard api response object
       */async submitConsentLog(a){const b=this.assignedConsents.filter(a=>this.form.acceptedConsents.includes(a.uniqueNumber)).map(a=>a.id);return this.$api.gdpr.web.consent.log.create({status:"accepted",statusTimestamp:Date.now(),sourceType:"cmsSite",consents:b,user:{userType:"webUser",email:a}})}}});export default Consentable;