
import AccountDashboard from '../profile/AccountDashboard.vue'
import OrderHistory from '../profile/OrderHistory.vue'

export default {
  components: { AccountDashboard, OrderHistory },
  data() {
    return {
      dashboardOpen: true,
      dashboardMobileOpen: false,
      ordersOpen: false,
      ordersMobileOpen: false,
    }
  },
  methods: {
    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 500)
    },
    activeProfile(e) {
      const el = e.currentTarget
      const navigation = document.getElementsByClassName('profile-li')

      const links = Array.from(navigation)

      links.forEach((link) => {
        link.classList.remove('active')
      })
      el.classList.add('active')
    },
    openDashboard() {
      this.dashboardOpen = true
      this.dashboardMobileOpen = !this.dashboardMobileOpen
      this.ordersOpen = false
      this.ordersMobileOpen = false
    },
    openOrders() {
      this.ordersOpen = true
      this.ordersMobileOpen = !this.ordersMobileOpen
      this.dashboardOpen = false
      this.dashboardMobileOpen = false
    },
  },
}
