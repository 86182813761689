
export default {
  data() {
    return {
      productDetailsShown: false,
    }
  },
  methods: {
    showProductDetail() {
      this.productDetailsShown = true
    },
    closeProductDetail() {
      this.productDetailsShown = false
    },
    formatDate(dateStr, locale) {
      if (!dateStr || !locale) return
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
      const date = new Date(dateStr)

      switch (locale) {
        case 'hr':
          return date.toLocaleDateString('hr-HR', options)
        case 'en':
          return date.toLocaleDateString('en-EN', options)
        case 'ar':
          return date.toLocaleDateString('ar-AR', options)
        case 'tl':
          return date.toLocaleDateString('tl-TL', options)
        default:
          return date
      }
    },
  },
}
