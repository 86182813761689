
import ControllerCart from 'Controllers/ControllerCart'
export default {
  components: {
    ControllerCart,
  },
  data() {
    return {
      loginModal: false,
      promoOpen: false,
    }
  },
  methods: {
    loggedIn() {
      this.loginModal = false
      this.$router.push(this.$Page.Checkout)
    },
    togglePromoModal() {
      this.promoOpen = !this.promoOpen
    },
    deleteAllCartItems(items, deleteItem) {
      items.forEach((item) => {
        deleteItem(item)
      })
    },
    add(item) {
      this.$set(item, 'qty', item.qty + 1)
    },
    remove(item) {
      if (item.qty > 1) {
        this.$set(item, 'qty', item.qty - 1)
      }
    },
  },
}
