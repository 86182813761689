
export default {
  data() {
    return {
      checkMark: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      const body = document.documentElement
      body.classList.add('success-page')
    })

    setTimeout(() => {
      this.checkMark = true
    }, 1000)
  },
  destroyed() {
    const body = document.documentElement
    body.classList.remove('success-page')
  },
}
