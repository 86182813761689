
export default {
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
    }
  },

  mounted() {
    this.$nextTick(() => {
      const body = document.documentElement
      body.classList.add('forgot-page')
    })
  },
  destroyed() {
    const body = document.documentElement
    body.classList.remove('forgot-page')
  },
  methods: {
    callback(locale) {
      if (locale === 'hr') {
        this.$router.push({ path: '/prijava' })
      } else this.$router.push({ path: '/login' })
    },
  },
}
