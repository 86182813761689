import { render, staticRenderFns } from "./uiProduct.vue?vue&type=template&id=055c4ff5&scoped=true"
import script from "./uiProduct.vue?vue&type=script&lang=js"
export * from "./uiProduct.vue?vue&type=script&lang=js"
import style0 from "./uiProduct.vue?vue&type=style&index=0&id=055c4ff5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "055c4ff5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Btn: require('/home/node/app/components/ui/btn.vue').default})
